// auth 2.0
/* eslint-disable react-hooks/exhaustive-deps */
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import env from "config/env";
import { useUpdateEffect } from "utils/customHook/customHook";
import { useAppDispatch, useAppSelector } from "hooks";
import styled from "styled-components";
import EditorJS, { API as EditorJSAPI } from "@editorjs/editorjs";
import { useTranslation } from "react-i18next";
import "./editor-js-custom.css";
import {
  createDocument,
  createDocumentHistory,
  setDocument,
  setEditorInstance,
  setEditorLastActiveBlockPosition,
  updateDocument,
  setUndoInstance,
  registerVideo,
  updateCurrentVideo,
  setIsPDFUploaderClicked,
  setIsReadOnly,
  setIsSaveDocumentCalled,
  setShowImageFullScreen,
  setNeedToSignUp,
  setIsEditorLoading,
  setIsEditorNoteLoading,
  deleteClip,
  setNoteBlocks,
} from "redux/actions/vdocsActions";
import { getEditorJSTools } from "components/editor/tools/EditorTools";
import { alertAutoSavingError, alertMaximumNoteSizeReached } from "../alerts/index";
import { useHistory, useLocation } from "react-router-dom";
import { setDefaultSettingCookie } from "utils/cookies/cookies";
import { i18nEn, i18nKo, insertParagraphToFirstBlock } from "./utils";
import ImageMarkup from "./components/ImageMarkup";
import PDFFileUploader from "./components/PDFFileUploader";
import Undo from "components/editor/tools/Undo";
import DragDrop from "components/editor/tools/DragDrop";
import TouchDeviceDragDrop from "components/editor/tools/TouchDeviceDragDrop";
import * as Sentry from "@sentry/browser";
import { debounce, throttle } from "lodash";
import { sendAmplitudeData } from "utils/amplitude";
import { useModal, useToast } from "@slid/slid-ips";
import ImageFullScreen from "./components/ImageFullScreen/ImageFullScreen";
import { showCustomModal } from "utils/modal";
import {
  sendDisplayVideoWithEditorRequestToParentWindow,
  sendHideExtensionViewerButton,
  sendShowExtensionViewerButton,
  sendVideoMetadataRequestToParentWindow,
} from "utils/extensionInterface/sendToExtension";
import { trackEvent } from "utils/eventTracking";
import { useMediaQuery } from "react-responsive";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import USER_PRIVILEGES, { SlidFeatures } from "utils/privilegeManager";
import { isTouchDevice } from "utils/utils";
import { SavedData } from "@editorjs/editorjs/types/data-formats";
import store from "redux/store";
import { OutputData } from "@editorjs/editorjs";
import EditorPlaceholder from "./components/EditorPlaceholder/EditorPlaceholder";
import useEditorStore from "../../store/useEditorStore";
import { setVideoMetadataResponseListener } from "utils/extensionInterface/setListenerFromExtension";

export enum LogLevels {
  VERBOSE = "VERBOSE",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}
const EDITOR_JS_HOLDER_ID = "slid-editor-js";
const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

const EditorComponent = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(["EditorComponent", "SweetAlertModals"]);
  const [shouldAutoSetDocTitle, setShouldAutoSetDocTitle] = useState(false);
  const { currentDocumentKey, updateNoteContent, focusEditor, blurEditor, initializeEditor, editorJSHolderRef, editorDocumentTitle, setEditorDocumentTitle } = useEditorStore();
  const { showModal, closeModal } = useModal();
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const { fileUploadInputRef } = useAppSelector((state) => state.editor);
  const { applicationType, lang, userData } = useAppSelector((state) => state.slidGlobal);
  const { isReadOnly, showImageMarkupPopup, currentDocument, editorInstance, undoInstance, currentVideo, isPDFUploaderClicked, isSaveDocumentCalled, showImageFullScreen } = useAppSelector(
    (state) => state.vdocs
  );
  const { isSTTToggledOn } = useAppSelector((state) => state.sttReducer);
  const { isAutoNotesSupported } = useAppSelector((state) => state.autoNotes);
  const [isEditorReady, setIsEditorReady] = useState<boolean>(false);
  const [editorOptions, setEditorOptions] = useState<any>(null);
  const [editorHolderTabIndex, setEditorHolderTabIndex] = useState<any>(-1);
  const isDocumentNew = useRef<boolean>(false);
  const isUserEditingNote = useRef<boolean>(false);
  const isTitleInputFocusedRef = useRef<boolean>(false);
  const titleTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const editorInstanceRef = useRef<(EditorJS & EditorJSAPI) | null>();
  const undoInstanceRef = useRef<any>(undoInstance);
  const currentDocumentRef = useRef<any>(currentDocument);
  const documentTitleRef = useRef<any>();
  const isReadOnlyRef = useRef<boolean>(isReadOnly);
  const imageMarkupProps = useRef<any>({});
  const imageFullScreenProps = useRef<any>({});
  const showImageMarkupPopupRef = useRef<boolean>(showImageMarkupPopup);

  const mode = useRef(location.pathname.split("/")[1] === "vdocs" ? "vdocs" : "plain docs");
  const isSharingMode = location.pathname.includes("/share/vdocs") || location.pathname.includes("/share/docs");
  const isDemoMode = document.location.href === `${SLID_WEB_APP_URL}/demo`;
  const isAuthRequired = document.location.href === `${SLID_WEB_APP_URL}/auth-required`;

  const ocrResponseIntervalId: any = useRef(null);
  const documentUpdateErrorCountRef = useRef(0);
  const historyCreationErrorCountRef = useRef(0);
  const currentVideoRef = useRef<any>(currentVideo);
  const isUserPrivilegedToEditNoteRef = useRef(true);
  const { showToast } = useToast();

  useEffect(() => {
    isUserPrivilegedToEditNoteRef.current = applicationType === "slidpocket" ? USER_PRIVILEGES.has(SlidFeatures.mobileNoteEditing) : USER_PRIVILEGES.has(SlidFeatures.noteEditing);
  }, [applicationType]);

  const convertSLTBlocksToParagraphWithContent = (content: any): OutputData => {
    const newBlocks = content.blocks.map((block: any) => {
      if (!block || !block.data) {
        return {
          id: block.id,
          type: "paragraph",
          data: {
            text: "",
          },
        };
      }
      if (block.type === "smartLiveText") {
        if (block.data) {
          const newBlock = {
            id: block.id,
            type: "paragraph",
            data: {
              text: block.data.text,
            },
          };
          return newBlock;
        }
      } else if (block.type === "mobileAutoNote") {
        return {
          id: block.id,
          type: "paragraph",
          data: {
            text: block.data.text,
          },
        };
      } else if (block.type === "image" && block.data.type === "mobileCaptureLoading") {
        return {
          id: block.id,
          type: "paragraph",
          data: {
            text: "",
          },
        };
      } else if (block.type === "mobileCaptureImage") {
        return {
          id: block.id,
          type: "paragraph",
          data: {
            text: "",
          },
        };
      } else {
        return block;
      }
    });
    return {
      time: content.time,
      blocks: newBlocks,
      version: content.version,
    };
  };

  const onClickMarkup = (params: any) => {
    imageMarkupProps.current = {
      ...params,
      saveDocument,
    };
  };

  const onClickFullScreen = (params: any) => {
    imageFullScreenProps.current = {
      ...params,
      closeModal,
    };
  };

  const isEditorEmpty = () => {
    return (
      editorInstanceRef.current?.blocks.getBlocksCount() === 0 ||
      (editorInstanceRef.current?.blocks.getBlocksCount() === 1 &&
        editorInstanceRef.current?.blocks.getBlockByIndex(0)?.name === "paragraph" &&
        editorInstanceRef.current?.blocks.getBlockByIndex(0)?.isEmpty) ||
      (editorInstanceRef.current?.blocks.getBlocksCount() === 1 && editorInstanceRef.current?.blocks.getBlockByIndex(0)?.name === "fake")
    );
  };

  useEffect(() => {
    setVideoMetadataResponseListener({
      responseHandler: (data: { videoTitle: string | null; videoDescription: string | null } | null) => {
        const videoTitle = data?.videoTitle;
        if (videoTitle && shouldAutoSetDocTitle && isEditorEmpty()) {
          setEditorDocumentTitle(videoTitle);
          documentTitleRef.current = videoTitle;
        }
        setShouldAutoSetDocTitle(false);
      },
    });
  }, [shouldAutoSetDocTitle]);

  useEffect(() => {
    if (isSaveDocumentCalled) {
      saveDocument();
      dispatch(setIsSaveDocumentCalled(false));
    }
  }, [isSaveDocumentCalled]);

  useEffect(() => {
    const checkPrivilege = async () => {
      if (!confirmPrivilege(SlidFeatures.pdfUpload)) return showInsufficientPrivilegeModal();
    };

    if (isPDFUploaderClicked) {
      saveDocument();
      checkPrivilege();
      fileUploadInputRef.current?.click();
      dispatch(setIsPDFUploaderClicked(false));
    }
  }, [isPDFUploaderClicked]);

  useEffect(() => {
    if (showImageFullScreen) {
      sendHideExtensionViewerButton();
      showCustomModal({ showModal, closeModal, history, customComponentContent: <ImageFullScreen {...imageFullScreenProps.current} /> });
    } else {
      sendShowExtensionViewerButton();
    }
  }, [showImageFullScreen]);

  useEffect(() => {
    showImageMarkupPopupRef.current = showImageMarkupPopup;
  }, [showImageMarkupPopup]);

  useUpdateEffect(() => {
    isReadOnlyRef.current = isReadOnly;
    if (!editorInstanceRef.current) return;

    const toggleReadOnlyToEditorInstance = async () => {
      await editorInstanceRef.current?.isReady;
      await editorInstanceRef.current?.readOnly?.toggle(isReadOnly);
    };

    toggleReadOnlyToEditorInstance();
  }, [isReadOnly]);

  useEffect(() => {
    currentVideoRef.current = currentVideo;
  }, [currentVideo]);

  useEffect(() => {
    if (!USER_PRIVILEGES.has(SlidFeatures.noteTitleUpdate)) return;
    if (applicationType !== "extension" || !isAutoNotesSupported) {
      return;
    }

    if (!currentDocument || (currentDocument && !currentDocument.title && shouldAutoSetDocTitle)) {
      sendVideoMetadataRequestToParentWindow();
    }
  }, [currentDocument?.document_key, applicationType, isAutoNotesSupported, shouldAutoSetDocTitle]);

  useEffect(() => {
    const EDITOR_JS_TOOLS = getEditorJSTools({
      editorInstance: editorInstanceRef.current!,
      isSharingMode,
      saveDocument,
      history,
      onClickMarkup,
      onClickFullScreen,
      showModal,
      closeModal,
      showToast,
      confirmPrivilege,
      showInsufficientPrivilegeModal,
    });

    const initialEditorOptions = {
      holder: EDITOR_JS_HOLDER_ID,
      tools: EDITOR_JS_TOOLS,
      i18n: lang === "ko" ? i18nKo : i18nEn,
      defaultBlock: "paragraph",
      autofocus: false,
      inlineToolbar: true,
      onReady: () => {
        /**
         * When the editor is ready, emit an event toward React Native (Slid Pocket):
         */
        try {
          if ((window as any).ReactNativeWebView) {
            // send data object to React Native (only string)
            (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: "EDITOR_READY" }));
          }
        } catch (err) {}
        setShouldAutoSetDocTitle(true);
      },
      onChange: () => {
        if (isDemoMode || isAuthRequired) return;
        debouncedTryCreateDocumentHistory();
        if (!isUserEditingNote.current && isEditorEmpty()) return;
        debouncedSaveDocument(false);
      },
      logLevel: LogLevels.ERROR,
    };
    setEditorOptions(initialEditorOptions);
  }, []);

  useUpdateEffect(async () => {
    const editorInstance = new EditorJS(editorOptions);
    try {
      await editorInstance.isReady;
      //@ts-ignore
      editorInstanceRef.current = editorInstance; // It is ignored because sometimes we access features that are not defined in the EditorJS type.
      await dispatch(setEditorInstance(editorInstance));
      setIsEditorReady(true);
    } catch (reason) {
      console.log(`Editor.js initialization failed because of ${reason}`);
    }
  }, [editorOptions]);

  const adjustScroll = () => {
    if (!editorInstanceRef.current?.blocks || isTitleInputFocusedRef.current) return;
    const currentBlockIndex = editorInstanceRef.current?.blocks.getCurrentBlockIndex();
    const currentBlock = editorInstanceRef.current?.blocks.getBlockByIndex(currentBlockIndex);

    if (!currentBlockIndex || !currentBlock) return;

    const editorInVdocs = document.querySelector("#editor-container");
    const editorInDocs = document.querySelector("#editor-component");
    const editorWrapper = editorInVdocs ? editorInVdocs : editorInDocs;

    if (!editorWrapper) return;

    const oneFourthOfWindowHeight = window.innerHeight * 0.25;
    const twoThirdsOfWindowHeight = window.innerHeight * 0.66;

    const editorWrapperRect = editorWrapper?.getBoundingClientRect() ?? { top: 0, height: 0 };
    const currentBlockRect = currentBlock.holder.getBoundingClientRect();
    const currentBlockClientYPosition = currentBlockRect.top - editorWrapperRect.top;
    const editorHeight = editorWrapperRect.height;
    const currentBlockHeight = currentBlockRect.height;

    if (!currentBlockClientYPosition || !editorHeight || !currentBlockHeight) return;
    const shouldScrollDown = currentBlockClientYPosition > window.innerHeight - oneFourthOfWindowHeight;
    const shouldScrollUp = currentBlockClientYPosition < 0;

    if (shouldScrollDown || shouldScrollUp) {
      editorWrapper.scrollTo({
        top: editorWrapper.scrollTop + currentBlockClientYPosition - twoThirdsOfWindowHeight,
        behavior: "smooth",
      });
    }
  };

  const registerVideoInfo = async ({ documentKey }: { documentKey: string }) => {
    if (!currentVideoRef.current || !documentKey) return;
    let isAlreadyRegistered = false;
    // filter out if video is already registered
    if (currentDocumentRef.current && currentDocumentRef.current["mapped_videos"]) {
      currentDocumentRef.current["mapped_videos"].forEach((mappedVideo: any) => {
        if (mappedVideo["video_key"] === currentVideoRef.current.videoKey) isAlreadyRegistered = true;
      });
    }
    if (isAlreadyRegistered) return;

    const videoRegisterInfo: any = await dispatch(
      registerVideo({
        videoInfo: currentVideoRef.current,
        documentKey,
      })
    );

    if (!videoRegisterInfo || videoRegisterInfo.error_message) return;

    sendAmplitudeData(`SLID_1_REGISTER_VIDEO`);

    // update current video Key
    const currentVideoKey = videoRegisterInfo["video_key"];

    await dispatch(
      updateCurrentVideo({
        videoKey: currentVideoKey,
      })
    );
  };

  const saveDocument = async (fetchCurrentDocument = true) => {
    if (isReadOnlyRef.current || !editorInstanceRef.current) return;

    if (!isUserPrivilegedToEditNoteRef.current) return;

    const updatedContents = await editorInstanceRef.current?.save();

    // if currentDocument is not saved in Redux State yet, create new one
    if (!currentDocumentRef.current || currentDocumentRef.current?.document_key === "new") {
      if (isDemoMode || isAuthRequired) return;

      const createdDocumentResponse: any = await dispatch(
        createDocument({
          title: documentTitleRef.current,
          content: updatedContents || {},
          origin: mode.current,
        })
      );

      dispatch(setNoteBlocks(updatedContents?.blocks));

      if (createdDocumentResponse.error_message) return;

      if (applicationType === "extension") {
        await registerVideoInfo({ documentKey: createdDocumentResponse["document_key"] });
      }

      setDefaultSettingCookie({
        property: `recentNoteDocumentKey`,
        value: createdDocumentResponse["document_key"],
      });
    }
    // if currentDocument is saved in Redux State already, update the document
    else {
      if (updatedContents?.blocks.length > env.maximum_supported_block_count) {
        if (applicationType !== "slidpocket") {
          alertMaximumNoteSizeReached({ target: "body" });
        } else {
          (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: "ALERT_MAXIMUM_NOTE_SIZE_REACHED" }));
        }
        return;
      }

      // don't make api call to update the document if the document is not changed
      const existingBlocks = store.getState().vdocs.noteBlocks;
      const newBlocks = updatedContents?.blocks;
      const isBlockChanged = JSON.stringify(existingBlocks) !== JSON.stringify(newBlocks);
      const isTitleChanged = documentTitleRef.current !== currentDocumentRef.current?.title;
      if (!isTitleChanged && !isBlockChanged) return;

      dispatch(setNoteBlocks(newBlocks));

      const updatedDocumentResponse: any = await dispatch(
        updateDocument({
          documentKey: currentDocumentRef.current["document_key"],
          title: documentTitleRef.current,
          content: updatedContents,
          fetchCurrentDocument,
          showError: false,
          origin: mode,
        })
      );

      /**
       * Show Auto Saving Error Alert if auto save failure three times.
       */
      if (updatedDocumentResponse?.error_message) {
        if (updatedDocumentResponse?.error_message !== "INSUFFICIENT_PRIVILEGES") documentUpdateErrorCountRef.current += 1;
        return;
      }

      documentUpdateErrorCountRef.current = 0;

      if (applicationType === "extension") {
        if (currentVideoRef.current && currentDocumentRef.current["mapped_videos"]) {
          await registerVideoInfo({ documentKey: currentDocumentRef.current["document_key"] });
        }
      }
    }
  };

  const onFocusEditorJsHolder = (e) => {
    focusEditor();
    isUserEditingNote.current = true;
  };

  const onMouseDownEditorToolbar = (e) => {
    if (!isEditorEmpty()) return;
    e.preventDefault();
    focusEditor();
  };

  const onBlurEditorJsHolder = () => {
    if (isReadOnlyRef.current) return;
    blurEditor(!isEditorEmpty());
    isUserEditingNote.current = false;
    dispatch(setEditorLastActiveBlockPosition(editorInstanceRef.current?.blocks.getCurrentBlockIndex()));
  };

  const debouncedAdjustScrollOnMobile = debounce((e) => {
    if (isReadOnlyRef.current) return;
    if (applicationType !== "slidpocket") return;

    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const rangeRect = range.getBoundingClientRect();

      let yPos = rangeRect.top + window.scrollY;

      // INFO: if there is no content in the block, it returns 0.
      // In that case, get the position of the block itself.
      if (yPos === 0) {
        const currentBlockIndex = editorInstance.blocks.getCurrentBlockIndex();
        const currentBlockElement = editorInstance.blocks.getBlockByIndex(currentBlockIndex).holder;

        if (currentBlockElement) {
          const blockRect = currentBlockElement.getBoundingClientRect();
          yPos = blockRect.top + window.scrollY;
        }
      }

      (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: "SCROLL_TO_Y_POS", yPos: yPos }));
    }
  }, 100);

  const onMouseDownEditor = (e) => {
    if (isReadOnlyRef.current) return;
    debouncedAdjustScrollOnMobile(e);
  };

  const onKeyDown = (e: any): any => {
    if (showImageMarkupPopupRef.current) return;
    if (applicationType === "slidpocket") {
      debouncedAdjustScrollOnMobile(e);
    }
    const saveDocumentByShortcut = async (e: KeyboardEvent) => {
      e.preventDefault();
      await saveDocument();
      sendAmplitudeData(`Save note manually`, {
        type: "ctrl + s",
      });
    };

    const ImageBlockToClipboard = () => {
      if (!editorInstanceRef.current?.blocks) return;

      const selectMediaBlock = (currentBlock: any) => {
        currentBlock.holder.classList.add("ce-block--selected");

        // copy to clipboard
        const data = new DataTransfer();
        const imageBlock = currentBlock.holder.getElementsByTagName("img")[0];
        const imageUrl = imageBlock.src;
        data.setData("text/plain", imageUrl);

        navigator.clipboard.writeText(imageUrl);
      };
      const currentBlockIndex = editorInstanceRef.current?.blocks.getCurrentBlockIndex();
      const currentBlock = editorInstanceRef.current?.blocks.getBlockByIndex(currentBlockIndex);

      if (currentBlock && (currentBlock.name === "image" || currentBlock.name === "video")) {
        selectMediaBlock(currentBlock);
      }
    };

    const currentBlockIndex = editorInstanceRef.current?.blocks.getCurrentBlockIndex()!;
    const currentBlock = editorInstanceRef.current?.blocks.getBlockByIndex(currentBlockIndex);

    const handleBackspaceKeyPress = (e) => {
      const currentBlockName = currentBlock?.name;
      if (!editorInstanceRef.current?.blocks || isTitleInputFocusedRef.current) return;
      if (currentBlockName === "image" && applicationType === "slidpocket") {
        e.preventDefault();
        currentBlock?.holder.classList.remove("ce-block--focused");
        editorInstanceRef.current?.caret.setToBlock(currentBlockIndex + 1, "end");
        return;
      }
      if (currentBlock && currentBlock.holder.className.includes("ce-block--selected")) {
        editorInstanceRef.current?.blocks.delete(editorInstanceRef.current?.blocks.getCurrentBlockIndex());
      }

      /**
       * For some blocks, we ask users whether they really want to delete the block.
       * So we add `focused` flag on the first try, and do remove it on the second time.
       *
       * This `safety check` is not implemented on touch device, since adding `focused` flag
       * prevents users from pressing backspace key twice in a row.
       */
      const focusBeforeDeleteBlocks = ["image", "video", "divider", "linkTool", "smartLiveText", "autoNotesLoader"];
      if (!(currentBlock && focusBeforeDeleteBlocks.includes(currentBlock.name))) {
        return;
      }
      if (isTouchDevice() || currentBlock.holder.className.includes("ce-block--focused")) {
        const currentBlockIndex = editorInstanceRef.current?.blocks.getCurrentBlockIndex();
        const currentBlock = editorInstanceRef.current.blocks.getBlockByIndex(currentBlockIndex);

        if (isSTTToggledOn && currentBlock?.name === "smartLiveText") return;

        if (currentBlock?.name === "image") {
          const removeImageBlock = async () => {
            if (applicationType === "slidpocket") return;
            const imageBlock = await currentBlock.save();
            const { clipKey } = (imageBlock as SavedData).data;
            if (clipKey) {
              dispatch(deleteClip({ clipKey }));
            }
          };
          removeImageBlock();
        }

        editorInstanceRef.current?.blocks.delete(currentBlockIndex);
        editorInstanceRef.current.blocks.insert();
        editorInstanceRef.current.caret.setToBlock(currentBlockIndex);
        return;
      }
      if (!currentBlock.holder.className.includes("ce-block--focused")) {
        currentBlock.holder.className += " ce-block--focused";
      }
    };

    const handleSpaceKeyPressOnImage = async () => {
      if (!editorInstanceRef.current?.blocks) return;
      if (isDemoMode) {
        dispatch(setNeedToSignUp(true));
        return;
      }
      await saveDocument();
      trackEvent({
        eventType: "Open image full screen by pressing spacebar",
      });
      dispatch(setShowImageFullScreen(true));
      onClickFullScreen({
        src: currentBlock?.holder.getElementsByTagName("img")[0].src,
      });
    };

    const code = e.code;
    const states = {
      alt: e.altKey,
      ctrl: e.ctrlKey,
      meta: e.metaKey,
      shift: e.shiftKey,
    };

    switch (code) {
      case "KeyS":
        if (states.ctrl || states.meta) saveDocumentByShortcut(e);
        break;
      case "KeyX":
      case "KeyC":
        if (states.ctrl || states.meta) ImageBlockToClipboard();
        break;
      case "Enter":
        adjustScroll();
        break;
      case "Backspace":
        handleBackspaceKeyPress(e);
        break;
      case "Space":
        if (applicationType === "slidpocket") return;
        if (currentBlock && currentBlock.name === "image" && !isTitleInputFocusedRef.current && !showImageMarkupPopupRef.current) {
          e.preventDefault();
          currentBlock.holder.classList.add("ce-block--focused");
          handleSpaceKeyPressOnImage();
        }
        break;
      default:
        break;
    }
  };

  // When Editor ready, set editor
  useUpdateEffect(() => {
    const setEditorEventListener = async () => {
      await editorInstanceRef.current?.isReady;
      const editorElement = document.getElementById(EDITOR_JS_HOLDER_ID)!;
      const editorToolbar = document.getElementsByClassName("ce-toolbar__content")[0];
      editorInstanceRef.current?.listeners.on(editorElement, "focus", onFocusEditorJsHolder, true);
      editorInstanceRef.current?.listeners.on(editorElement, "blur", onBlurEditorJsHolder, true);
      editorInstanceRef.current?.listeners.on(editorElement, "mousedown", onMouseDownEditor, true);
      editorInstanceRef.current?.listeners.on(editorToolbar, "mousedown", onMouseDownEditorToolbar, true);
      window.onkeydown = onKeyDown;
    };

    const setEditorToolsInstance = async () => {
      if (!editorInstanceRef.current) return;
      if (isReadOnlyRef.current) return;

      await editorInstanceRef.current?.isReady;
      if (isTouchDevice()) {
        new TouchDeviceDragDrop(editorInstanceRef.current, isReadOnlyRef.current);
      } else {
        new DragDrop(editorInstanceRef.current, isReadOnlyRef.current);
      }
      const newUndoInstance = new Undo({
        editor: editorInstanceRef.current,
        onUpdate() {},
        maxLength: 30,
        config: {
          shortcuts: {
            undo: "CMD+Z",
            redo: "CMD+SHIFT+Z",
          },
        },
      });
      dispatch(setUndoInstance(newUndoInstance));
      undoInstanceRef.current = newUndoInstance;
    };

    // DO After editor is ready
    setEditorEventListener();
    setEditorToolsInstance();
  }, [isEditorReady, isReadOnly, isSTTToggledOn]);

  useEffect(() => {
    const removeEditorEventListener = () => {
      const editorElement = document.getElementById(EDITOR_JS_HOLDER_ID)!;
      const editorToolbar = document.getElementsByClassName("ce-toolbar__content")[0];
      editorInstanceRef.current?.listeners.off(editorElement, "focus", onFocusEditorJsHolder);
      editorInstanceRef.current?.listeners.off(editorElement, "blur", onBlurEditorJsHolder);
      editorInstanceRef.current?.listeners.off(editorElement, "mousedown", onMouseDownEditor);
      editorInstanceRef.current?.listeners.off(editorToolbar, "mousedown", onMouseDownEditorToolbar);
      window.onkeydown = null;
    };

    return function cleanup() {
      removeEditorEventListener();
      ocrResponseIntervalId.current = null;
      isUserEditingNote.current = false;
      dispatch(setEditorInstance(null));
      editorInstanceRef.current = null;
      dispatch(setDocument(null));
      currentDocumentRef.current = null;
      dispatch(setUndoInstance(null));
      undoInstanceRef.current = null;
      updateNoteContent(false);
      initializeEditor();
    };
  }, []);

  useUpdateEffect(() => {
    if (documentUpdateErrorCountRef.current >= 3) {
      alertAutoSavingError().then(({ isConfirmed }) => {
        isConfirmed && history.go(0);
      });
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("SLID_WEB_AUTO_SAVE_ERROR");
      });
    }
  }, [documentUpdateErrorCountRef.current]);

  useUpdateEffect(() => {
    if (historyCreationErrorCountRef.current >= 2) {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("SLID_HISTORY_CREATION__ERROR");
      });
    }
  }, [historyCreationErrorCountRef.current]);

  // When current document is changed, set current document key
  useEffect(() => {
    if (!isEditorReady) return;
    currentDocumentRef.current = currentDocument;
    useEditorStore.setState({
      currentDocumentKey: currentDocument ? currentDocument["document_key"] : "new",
    });

    const params = new URLSearchParams(location.search);
    const isTimeStampButtonClicked = params.has("start") && params.has("v");
    if (isTimeStampButtonClicked) return;

    // TODO: Move to proper place - Where new document is created while adding new content in '/new'
    if (currentDocument && applicationType !== "slidpocket") history.replace(`./${currentDocument["document_key"]}`);
  }, [currentDocument, isEditorReady]);

  const togglePrivilegeReadOnlyState = () => {
    const canEdit = isUserPrivilegedToEditNoteRef.current || isDemoMode;
    dispatch(setIsReadOnly(!canEdit));
    //INFO: We should manually toggle the editor, in case the useEffect for readOnly does not run again because the readOnly redux state is not changed.
    if (!canEdit) editorInstanceRef.current?.readOnly.toggle(true);
  };

  const userPaymentType = userData?.payment;
  useEffect(() => {
    togglePrivilegeReadOnlyState();
  }, [userPaymentType]);

  // When document key is changed
  useUpdateEffect(() => {
    const showExtensionVideoOnEditorReady = () => {
      if (applicationType === "extension" && (location.pathname.includes("vdocs") || location.pathname.includes("demo"))) {
        sendDisplayVideoWithEditorRequestToParentWindow();
      }
    };

    //if its a new note, loading state is removed to show the editor right away
    const toggleNewNoteLoadingState = () => {
      const isNewNote = location.pathname.includes("/docs/new") || location.pathname.includes("/vdocs/new") || location.pathname.includes("/demo");

      if (isNewNote) {
        dispatch(setIsEditorLoading(false));
        showExtensionVideoOnEditorReady();
      }
    };

    const initializeUndoInstance = async () => {
      if (isReadOnlyRef.current) return;
      if (!undoInstanceRef.current) return;
      await undoInstanceRef.current.clear();
      if (!currentDocumentRef.current || currentDocumentRef.current?.content === undefined) return;
      await undoInstanceRef.current.initialize(JSON.parse(currentDocumentRef.current?.content));
    };

    const resetEditorForNewDocument = () => {
      isUserEditingNote.current = false;
      isDocumentNew.current = true;
      setEditorDocumentTitle("");
      documentTitleRef.current = "";
      editorInstanceRef.current?.clear();
      currentDocumentRef.current = currentDocument?.mapped_videos ? currentDocument : null;
      toggleNewNoteLoadingState();
      if (undoInstanceRef.current) undoInstanceRef.current.clear();
      togglePrivilegeReadOnlyState();
      updateNoteContent(false);
    };

    const renderExistingDocumentToEditor = async () => {
      await editorInstanceRef.current?.isReady;

      // If new document is saved, the document key changes. We need to keep focusing on note content or title input and keep it in edit mode when document key is changed.
      if (isDocumentNew.current && isUserEditingNote.current) {
        isDocumentNew.current = false;
        return;
      }

      isDocumentNew.current = false;
      isUserEditingNote.current = false;

      setEditorDocumentTitle(currentDocumentRef.current?.title || "");
      documentTitleRef.current = currentDocumentRef.current?.title || "";

      const fakeContent = {
        time: new Date().getTime(),
        blocks: [
          {
            id: "1",
            name: "fake",
            type: "paragraph",
            data: {
              text: "",
            },
          },
        ],
        version: "2.24.3",
      };

      // If a document was created without content, it would be not rendered so we need to create fake content to render it..
      // currentDocumentRef.current["content"] === "{}" means currentDocument was created without content.
      // For example, createDocument from myDocs.
      if (currentDocumentRef.current && currentDocumentRef.current?.content === "{}") {
        if (currentDocumentRef.current?.content) currentDocumentRef.current.content = JSON.stringify(fakeContent);
      }
      // TODO : Since we now convert SLT blocks before it is rendered, remove all other convertSLTBlocksToParagraph function calls.
      const sttBlockFilteredContent = convertSLTBlocksToParagraphWithContent(JSON.parse(currentDocumentRef?.current?.content ?? JSON.stringify(fakeContent))); // undefined is not valid json, so it causes an error here.
      //Render the content of the doc into editor js if it exists
      await editorInstanceRef.current?.render(sttBlockFilteredContent).then(() => {
        updateNoteContent(!isEditorEmpty());
        //Turn off loading state after rendering note with content
        dispatch(setIsEditorLoading(false));
        showExtensionVideoOnEditorReady();
        editorInstanceRef.current?.save(); // For updating DB also
      });

      toggleNewNoteLoadingState();
      togglePrivilegeReadOnlyState();
    };

    const moveScrollAndToolbarToTop = () => {
      const editorInVdocs = document.querySelector("#editor-container");
      const editorInDocs = document.querySelector("#editor-component");
      const editorWrapper = editorInVdocs ? editorInVdocs : editorInDocs;

      // move scroll to top
      editorWrapper && (editorWrapper.scrollTop = 0);
      // move inline toolbar position to top
      document.querySelector(".ce-inline-toolbar") && ((document.querySelector(".ce-inline-toolbar") as HTMLDivElement).style.top = "0px");
    };

    dispatch(setEditorLastActiveBlockPosition(null));

    currentDocumentKey === "new" ? resetEditorForNewDocument() : renderExistingDocumentToEditor();
    moveScrollAndToolbarToTop();
    dispatch(setIsEditorNoteLoading(false));
    const initialUndoInstantTimeout = setTimeout(() => {
      // set undo instance at the end of the useEffect to prevent undo instance from being initialized twice.
      initializeUndoInstance();
    });

    return () => {
      clearTimeout(initialUndoInstantTimeout);
    };
  }, [currentDocumentKey]);

  useUpdateEffect(() => {
    if (!titleTextAreaRef.current) return;
    // For auto resizing the height of title textarea
    titleTextAreaRef.current!.style.height = isMobile && applicationType !== "desktop" ? `36px` : `48px`;
    titleTextAreaRef.current!.style.height = `${titleTextAreaRef.current?.scrollHeight}px`;
  }, [editorDocumentTitle]);

  useUpdateEffect(() => {
    if (!isEditorReady || !isSharingMode) return;
    const editorElement = document.querySelector("#slid-editor-js");
    setTimeout(() => {
      const aTagArray = editorElement?.querySelectorAll("div.ce-block a");
      aTagArray?.forEach((element) => {
        // @ts-ignore
        element?.removeEventListener("click", handleLinkClick);
        // @ts-ignore
        element?.addEventListener("click", handleLinkClick);
      });
    });

    const handleLinkClick = (e: any) => {
      const url = e.target.href;
      trackEvent({
        eventType: "click CONTENT LINK in shared note",
        eventProperties: {
          document_key: currentDocumentKey,
          target_url: url,
        },
      });
    };
  }, [isSharingMode, currentDocumentKey]);

  const debouncedSaveDocument = useRef(debounce((fetchCurrentDocument) => saveDocument(fetchCurrentDocument), 500)).current;
  const debouncedTryCreateDocumentHistory = useRef(debounce(() => tryHistoryCreation(), 1000 * 10)).current;
  const throttleTryCreateDocumentHistory = useRef(throttle(() => createHistory(), 1000 * 10 * 60)).current;

  const tryHistoryCreation = () => {
    if (!currentDocumentRef) return;
    throttleTryCreateDocumentHistory();
  };

  const createHistory = async () => {
    if (!USER_PRIVILEGES.has(SlidFeatures.makeNoteHistory) || !currentDocumentRef.current || currentDocumentRef.current?.document_key === "new") return;
    const historyCreationResponse: any = await dispatch(
      createDocumentHistory({
        documentKey: currentDocumentRef.current["document_key"],
        showError: historyCreationErrorCountRef.current !== 0,
        origin: mode.current,
      })
    );

    /**
     * Send History Creation Sentry Error if history creation failure two times.
     */
    if (!historyCreationResponse) {
      historyCreationErrorCountRef.current += 1;
      return;
    }

    historyCreationErrorCountRef.current = 0;
  };

  return (
    <EditorWrapper id={`editor-wrapper`}>
      {/*  Slid Pocket should have its own header: */}
      {location.pathname !== "/slid-pocket-editor" && isEditorReady && (
        <EditorTitle
          isMobile={isMobile && applicationType !== "desktop"}
          isReadOnly={isReadOnly}
          onClick={(e) => {
            e.preventDefault();
            if (!confirmPrivilege(SlidFeatures.noteTitleUpdate) && !isDemoMode) return showInsufficientPrivilegeModal();
          }}
        >
          <EditorTitleTextArea
            isMobile={isMobile && applicationType !== "desktop"}
            autoComplete={`off`}
            ref={titleTextAreaRef}
            readOnly={isReadOnly}
            placeholder={t("TitleText", { ns: "EditorComponent" })}
            value={isSharingMode && !editorDocumentTitle ? t<string>("Untitled", { ns: "EditorComponent" }) : editorDocumentTitle}
            data-testid={`editor-title`}
            onChange={(event) => {
              if (!USER_PRIVILEGES.has(SlidFeatures.noteTitleUpdate) && !isDemoMode) return;
              event.target.value = event.target.value.replace(/[\r\n\v]+/g, "");
              setEditorDocumentTitle(event.target.value);
              documentTitleRef.current = event.target.value;
              if (isDemoMode || isAuthRequired) return;
              debouncedSaveDocument(true);
              debouncedTryCreateDocumentHistory();
            }}
            onKeyDown={(event: KeyboardEvent) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (!isUserPrivilegedToEditNoteRef.current && !isDemoMode) return;
                insertParagraphToFirstBlock({ editorInstance });
              }
            }}
            onBlur={() => {
              isTitleInputFocusedRef.current = false;
              isUserEditingNote.current = false;
            }}
            onFocus={() => {
              isTitleInputFocusedRef.current = true;
              isUserEditingNote.current = true;
            }}
            disabled={isReadOnly}
          ></EditorTitleTextArea>
        </EditorTitle>
      )}
      <EditorContentContainer>
        {/* NOTE: EditorJSHolder handle EditorJS */}
        <EditorJSHolder
          ref={editorJSHolderRef}
          id={EDITOR_JS_HOLDER_ID}
          isMobile={isMobile && applicationType !== "desktop"}
          isShareMode={isReadOnly}
          /** regarding `tabIndex`:
           *    case -1: one-click capture is triggered even when editor is not focused
           *    case undefined: allow editor to have natural focus behavior
           */
          tabIndex={editorHolderTabIndex}
          onFocus={() => {
            /**
             * Once editor is focused, set tabIndex to undefined to let editor have natural focus behavior.
             */
            if (editorHolderTabIndex !== -1) return;
            setEditorHolderTabIndex(undefined);
          }}
        />
        <EditorPlaceholder saveDocument={saveDocument} />
      </EditorContentContainer>
      <PDFFileUploader saveDocument={saveDocument} ref={fileUploadInputRef} />
      {showImageMarkupPopup && <ImageMarkup {...imageMarkupProps.current} />}
    </EditorWrapper>
  );
};

export default EditorComponent;

const EditorWrapper = styled.div``;

const EditorTitle = styled.h1<{ isMobile: boolean; isReadOnly: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? `2.4rem` : `3.4rem`)};
  width: ${({ isMobile, isReadOnly }) => (isMobile && isReadOnly ? `calc(100% - 48px)` : `87%`)};
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditorTitleTextArea = styled.textarea<{ isMobile: boolean }>`
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? `36px` : `48px`)};
  padding: 0;
  border: 0;
  outline: 0;
  resize: none;
  font-weight: 700;
  line-height: 1.5;
  -webkit-appearance: none;
  background: none;
  color: var(--gray17);
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::placeholder {
    font-weight: 700;
    color: var(--gray4);
  }
`;
const EditorContentContainer = styled.div`
  position: relative;
`;

const EditorJSHolder = styled.div<{ isMobile: boolean; isShareMode: boolean }>`
  .codex-editor__redactor {
    padding-bottom: ${({ isShareMode }) => isShareMode && "0px !important"};
  }

  .ce-block__content {
    font-size: ${({ isMobile }) => (isMobile ? "1.5rem !important" : "1.6rem !important")};
  }

  h2 {
    font-size: ${({ isMobile }) => (isMobile ? "2.4rem" : "2.8rem")};
    font-weight: 700;
  }

  h3 {
    font-size: ${({ isMobile }) => (isMobile ? "2rem" : "2.4rem")};
    font-weight: 700;
  }

  h4 {
    font-size: ${({ isMobile }) => (isMobile ? "1.7rem" : "2rem")};
    font-weight: 700;
  }

  ${(props) =>
    props.isMobile &&
    props.isShareMode &&
    `
      .codex-editor .ce-block__content,
      .codex-editor .ce-toolbar__content {
        width: calc(100% - 48px);
      }
    `}
`;
